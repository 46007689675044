* {
  margin: 0px;
  padding: 0px;
}

iframe{
  pointer-events: none;
  display: none !important;
  z-index:0 !important;
  height: 0 !important;
  width: 0 !important;
  top: 200% !important;
  left: 200% !important;
}
.slick-prev:before ,.slick-next:before{
  color: #000!important;
}

html {
  height  : 100%;
  overflow: hidden;
  position: relative;
}

body {
  font-family: 'Poppins', 'Rubik', sans-serif;
  height  : 100%;
  /* overflow: auto; */
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.App {
  text-align: center;
}

.t4e{
  transition: all 0.4s;
}

.navOpen{
  left: 250px;
  width:calc(100% - 250px);
}

.navClose{
  left: 55px;
  width:calc(100% - 55px);
}

.ofC {
  object-fit: cover;
}

.ofCo {
  object-fit: contain;
}

.opC {
  object-position: center;
}

.opT {
  object-position: top;
}
.opL {
  object-position: left;
}
.opR {
  object-position: right;
}
.opB {
  object-position: bottom;
}

.ffPo {
  font-family: 'Poppins', Roboto, "Helvetica Neue", sans-serif;
}

.ffR {
  font-family: 'Rubik', Roboto, "Helvetica Neue", sans-serif;
}

.bsGm{
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.bsM{
  box-shadow: 0px 3px 16px #0000000D;
}

.tlF{
  table-layout: fixed;
}

.load-wrapp .line:nth-last-child(1) {
	animation: loadingC .6s .1s linear infinite;
} 
.load-wrapp .line:nth-last-child(2) {
	animation: loadingC .6s .2s linear infinite;
} 
.load-wrapp .line:nth-last-child(3) {
	animation: loadingC .6s .3s linear infinite;
}

@keyframes loadingC {
	0% {transform: translate(0,0);} 
	50% {transform: translate(0,15px);} 
	100% {transform: translate(0,0);} 
}

.ghost-card-pulsate-drk {
	background: linear-gradient(-45deg, #DDDDDD, rgba(3, 19, 1, 0.5), #DDDDDD, rgba(3, 19, 1, 0.5));
	background-size: 400% 400%;
	-webkit-animation: Gradient 2.25s ease infinite;
	-moz-animation: Gradient 2.25s ease infinite;
	animation: Gradient 2.25s ease infinite;
}
.offlineGradient {
  background: linear-gradient(-45deg, #999, rgba(3, 19, 1, 0.5), #000, rgba(3, 19, 1, 0.5));
  background-size: 400% 400%;
	-webkit-animation: Gradient 2.25s ease infinite;
	-moz-animation: Gradient 2.25s ease infinite;
	animation: Gradient 2.25s ease infinite;
}
@-webkit-keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } }
@-moz-keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } } 
@keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } }

.fadeIn{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-duration:0.4s;
  animation-fill-mode:both;
}
@-webkit-keyframes fadeIn {0% {opacity: 0; transform: translate3d(0,50px,0); } to {opacity: 1; transform: translate3d(0,0,0)} }
@keyframes fadeIn {0% {opacity: 0; transform: translate3d(0,50px,0);} to {opacity: 1; transform: translate3d(0,0,0);} } 

.fadeInOutOnce{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: fadeInOut;
  animation-duration:6s;
  animation-fill-mode:forwards;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.fadeOut{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: fadeOut;
  animation-duration:4s;
  animation-fill-mode:both;
  /* animation-iteration-count: 1; */
}
@-webkit-keyframes fadeOut {0% {opacity: 1; transform: translate3d(0,0,0);} to {opacity: 0; transform: translate3d(0,50px,0);} }
@keyframes fadeOut {0% {opacity: 1; transform: translate3d(0,0,0)} to {opacity: 0; transform: translate3d(0,50px,0);} } 

.fadeInLeft{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: fadeInLeft;
  animation-duration:0.7s;
  animation-fill-mode:both;
}
@-webkit-keyframes fadeInLeft {0% {opacity: 0; transform: translate3d(-50%,0,0); } to {opacity: 1; transform: translate3d(0,0,0)} }
@keyframes fadeInLeft {0% {opacity: 0; transform: translate3d(-50%,0,0);} to {opacity: 1; transform: translate3d(0,0,0);} } 

.fadeInRight{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: fadeInRight;
  animation-duration:0.7s;
  animation-fill-mode:both;
}
@-webkit-keyframes fadeInRight {0% {opacity: 0; transform: translate3d(50%,0,0); } to {opacity: 1; transform: translate3d(0,0,0)} }
@keyframes fadeInRight {0% {opacity: 0; transform: translate3d(50%,0,0);} to {opacity: 1; transform: translate3d(0,0,0);} } 

.ffRw{flex-flow: row wrap}

.wsNpar > div.errCss {white-space: nowrap; overflow: hidden;}
.trnsRR{transform: rotate(180deg)}
.errorHeight {height: 12px;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spinLoader {
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
    -webkit-transform: rotate(360deg); 
    transform:rotate(360deg); 
  } 
}


select:focus{
  outline: none;
}

.ghost-card-pulsate{
  background: linear-gradient(-45deg, #DDDDDD, rgba(195, 132, 100, 0.3), #DDDDDD, rgba(195, 132, 100, 0.3));
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}
@-webkit-keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } }
@-moz-keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } } 
@keyframes Gradient {0% {background-position: 0% 50% } 50% {background-position: 100% 50% } 100% {background-position: 0% 50% } }

.ScaleFadeOut{
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-name: ScaleFadeOut;
  animation-duration:1s;
  animation-fill-mode:both;
}

@-webkit-keyframes ScaleFadeOut {0% {opacity: 1; -webkit-transform: scale(1.0); display: block;} to {opacity: 0; -webkit-transform: scale(50.0); display: none;} }
@keyframes ScaleFadeOut {0% {opacity: 1; transform: scale(1.0); display: block;} to {opacity: 0; transform: scale(50.0); display: none;} } 

.fadeInOut{
  -webkit-animation-name: fadeInOut;
  animation-name: fadeInOut;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode:both;
}

@-webkit-keyframes fadeInOut {0% {opacity: 0; } 50% {opacity: 1; } 100% {opacity: 0;} }
@keyframes fadeInOut {0% {opacity: 0; } 50% {opacity: 1; } 100% {opacity: 0;} }

.backdrop {
  background-color: rgba(0,0,0,0.4);
}

.hA{
  height: auto;
}

.wA{
  width: auto;
}

.sideDrawerNav {
  transform: translateX(-150%);
  transition: transform 0.2s ease-in-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sideDrawerNav.open {
  transform: translateX(0);
}

.trns0{-o-transform:none;-ms-transform:none;-moz-transform:none;-webkit-transform:none;transform:none}
.trnsU{-o-transform:translateY(-200%);-ms-transform:translateY(-200%);-moz-transform:translateY(-200%);-webkit-transform:translateY(-200%);transform:translateY(-200%)}
.trnsD{-o-transform:translateY(200%);-ms-transform:translateY(200%);-moz-transform:translateY(200%);-webkit-transform:translateY(200%);transform:translateY(200%)}          
.trnsR{-o-transform:translateX(200%);-ms-transform:translateX(200%);-moz-transform:translateX(200%);-webkit-transform:translateX(200%);transform:translateX(200%)}
.trnsL{-o-transform:translateX(-200%);-ms-transform:translateX(-200%);-moz-transform:translateX(-200%);-webkit-transform:translateX(-200%);transform:translateX(-200%)}                
/* heartbeat */
@keyframes animateHeart {0% {transform: scale(0.8); } 5% {transform: scale(0.9); } 10% {transform:  scale(1); } 15% {transform:  scale(1.2); } 50% {transform: scale(0.8); } 100% {transform: scale(1); } } 
.heartBeat{ animation: animateHeart 1.2s; animation-iteration-count: 1;}

@media screen and (min-width: 100px) {
  .navClose.mobile{
    left: 0px;
    width:100%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }
  
}
@media screen and (max-width: 500px) {
  html, body {
    height  : auto;
    overflow: auto;
  }
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #333333;
}

.sweepAcross {
  -webkit-animation-name: sweepAcross;
  animation-name: sweepAcross;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes sweepAcross {0% {left: 100%; } 100% {left: -100%;} }
@keyframes sweepAcross {0% {left: 100%; } 100% {left: -100%;} }

.sweepAcrossDelayed {
  -webkit-animation-name: sweepAcross;
  animation-name: sweepAcross;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 10s;
  -webkit-animation-delay: 10s;
}

@-webkit-keyframes sweepAcross {0% {left: 100%; } 100% {left: -100%;} }
@keyframes sweepAcross {0% {left: 100%; } 100% {left: -100%;} }

@media screen and (max-width: 800px) {
  .sweepAcrossDelayed {
    display: none;
  }
}

.link:hover {
  text-decoration: underline;
}

.expand-box {
  transition: max-height 0.5s ease-in-out;
}
textarea {
  font-family: 'Rubik';
  resize: vertical;
}

.plusMinusSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 10px;
}

.plusMinusSelectParent:before {
  position: absolute;
  top: 0px;
  bottom: 6px;
  margin: auto;
  right: 20px;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: inline-block;
  height: 8px;
  transform: rotate(134deg);
  width: 8px;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  background-color: #4d4d4d;
  
}

 .slick-dots li button:before{
    font-family: 'slick';
    line-height: 0px!important;
    position: absolute;
    top: 0;
    left: 0;
    width: 6px!important;
    height: 6px!important;
    content: ''!important;
    border: 2px solid #4d4d4d;
    text-align: center;
    opacity: 1!important;
    color: #4d4d4d;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 50%!important;
}

.t_center{
  transform: translate(-50%, -50%);
}

div:focus ,img:focus {
  outline: none !important;}

.lineClamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.lineClamp3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.gm-button-elevate:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
  background-color: rgba(243, 61, 5, 0.36);
}

.gm-button-elevate:hover .bgPo8 {
  background-color: rgba(243, 61, 5, 1);
}

/* Elevate Card */

.gm-elevated-card {
  position: relative;
  background-color: white;
  border-radius: 16px;
  box-shadow: rgb(179 186 197) 0px 0px 1px 0px, rgb(193 199 208) 0px 2px 4px -1px;
  transition: all 300ms ease 0s;
  --show-action: 0;
}

.gm-elevated-card:hover, .gm-elevated-card:active, .gm-elevated-card:focus-within {
  cursor: pointer;
  --show-action: 1;
  box-shadow: rgb(179 186 197) 0px 0px 1px 0px, rgb(193 199 208) 0px 16px 32px -12px;
  transition: all 300ms ease 0s;
  transition-duration: 300ms;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-property: box-shadow;
  transform: scale(1.02);
  transform: translateX(-2);
}

/* Solid Gray Button */

.gm-solid-gray-button {
  appearance: none;
  background-color: #DDDDDD;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  margin: 0;
  min-width: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

/* Solid Button */

.gm-solid-button {
  appearance: none;
  background-color: #c38464;
  border: 2px solid #c38464;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  margin: 0;
  min-width: 0;
  outline: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-solid-button:disabled, .gm-solid-gray-button:disabled {
  /* pointer-events: none; */
  cursor: not-allowed;
  background-color: #AAAAAA;
  border-color: #AAAAAA;
}

.gm-solid-button:hover, .gm-solid-gray-button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  /* background-color: #905739;
  border: 2px solid #905739; */
  transform: translateY(-2px);
}

.gm-solid-button:active, .gm-solid-gray-button:active {
  box-shadow: none;
  transform: translateY(0);
}

/* Outline Button */

.gm-outline-button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #c38464;
  box-sizing: border-box;
  color: #c38464;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  margin: 0;
  min-width: 0;
  outline: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-outline-button:disabled {
  pointer-events: none;
}

.gm-outline-button.disabled {
  pointer-events: none;
  border: 2px solid #aaaaaa;
  color: #aaaaaa;
}

.gm-outline-button:hover {
  color: #fff;
  background-color: #c38464;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.gm-outline-button.disabled:hover {
  color: #fff;
  background-color: #aaaaaa;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
}

.gm-outline-button:active {
  box-shadow: none;
  transform: translateY(0);
}

/* outline danger button */

.gm-outline-danger-button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #F33C05;
  box-sizing: border-box;
  color: #F33C05;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  min-width: 0;
  outline: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-outline-danger-button.disabled {
  cursor: not-allowed;
  border: 2px solid #aaaaaa;
  color: #aaaaaa;
}

.gm-outline-danger-button:hover {
  color: #fff;
  background-color: #F33C05;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.gm-outline-danger-button.disabled:hover {
  color: #fff;
  background-color: #aaaaaa;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
}

.gm-outline-danger-button:active {
  box-shadow: none;
  transform: translateY(0);
}

/* solid danger button */

.gm-solid-danger-button {
  appearance: none;
  background-color: #F33C05;
  border: 2px solid #F33C05;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  min-width: 0;
  outline: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-solid-danger-button.disabled {
  border: 2px solid #aaaaaa;
  color: #aaaaaa;
  cursor: not-allowed;
  background-color: #AAAAAA;
}

.gm-solid-danger-button:hover {
  color: #fff;
  background-color: #F33C05;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.gm-solid-danger-button.disabled:hover {
  color: #fff;
  background-color: #aaaaaa;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
}

.gm-solid-danger-button:active {
  box-shadow: none;
  transform: translateY(0);
}

/* solid success button */

.gm-solid-success-button {
  appearance: none;
  background-color: #0F993B;
  border: 2px solid #0F993B;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  min-width: 0;
  outline: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-solid-success-button.disabled {
  cursor: not-allowed;
  border: 2px solid #aaaaaa;
  color: #FFFFFF;
  background-color: #AAAAAA;
}

.gm-solid-success-button:hover {
  color: #fff;
  background-color: #0F993B;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.gm-solid-success-button.disabled:hover {
  color: #fff;
  background-color: #aaaaaa;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
}

.gm-solid-success-button:active {
  box-shadow: none;
  transform: translateY(0);
}

/* outline success button */

.gm-outline-success-button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #0F993B;
  box-sizing: border-box;
  color: #0F993B;
  cursor: pointer;
  display: inline-block;
  font-family: 'Rubik', 'Montserrat', Roboto, "Helvetica Neue", sans-serif;
  line-height: normal;
  min-width: 0;
  outline: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.gm-outline-success-button.disabled {
  cursor: not-allowed;
  border: 2px solid #aaaaaa;
  color: #aaaaaa;
}

.gm-outline-success-button:hover {
  color: #fff;
  background-color: #0F993B;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.gm-outline-success-button.disabled:hover {
  color: #fff;
  background-color: #aaaaaa;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
}

.gm-outline-success-button:active {
  box-shadow: none;
  transform: translateY(0);
}

.gm-gradient-brown {
  background: hsla(33, 91%, 87%, 1);
  background: linear-gradient(45deg, hsla(33, 91%, 87%, 1) 0%, hsla(23, 63%, 69%, 1) 100%);
  background: -moz-linear-gradient(45deg, hsla(33, 91%, 87%, 1) 0%, hsla(23, 63%, 69%, 1) 100%);
  background: -webkit-linear-gradient(45deg, hsla(33, 91%, 87%, 1) 0%, hsla(23, 63%, 69%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FCE2C2", endColorstr="#E1A37D", GradientType=1 );
}

@media only screen and (max-width: 1400px){
  .allBanner{
    max-height: 580px!important;
  }
  .scale-img{
    max-height: 540px!important;
  }
}
@media only screen and (max-width: 410px){
  .mMobileNumber{
    max-width: 150px!important;
  }
  .mCountryCode{
    max-width: 150px!important;
    padding-left: 0px!important;
  }
}

@media only screen and (min-width: 1400px){
  .carpet_desk{
    right: 34%;
    top: 87%;
  }
  .women_desk{
    right: 33%;
    top: 56%;
  }
  .men_desk{
    right: 37%;
    top: 77%;
  }
  .kids_desk{
    left: 6%;
    top: 66%;
  }
  .accent_desk{
    right: 22%;
    top: 49%;
  }
  .bedroom_desk{
    right: 26%;
    top: 65%;
  }
  .homeoffice_desk{
    left: 14%;
    top: 40%;
  }
  .lighting_desk{
    right: 31%;
    top: 70%;
  }
  .walldecor_desk{
    right: 31%;
    top: 57%;
  }
  .roomdecor_desk{
    right: 27%;
    top: 52%;
  }
  .carton_desk{
    left: 8%;
    top: 56%;
  }
}
@media only screen and (min-width: 1500px){
  .carpet_desk{
    right: 35%;
    top: 87%;
  }
  .women_desk{
    right: 34%;
    top: 56%;
  }
  .men_desk{
    right: 37%;
    top: 77%;
  }
  .kids_desk{
    left: 6%;
    top: 66%;
  }
  .accent_desk{
    right: 23%;
    top: 49%;
  }
  .bedroom_desk{
    right: 27%;
    top: 65%;
  }
  .lighting_desk{
    right: 32%;
    top: 70%;
  }
  .walldecor_desk{
    right: 32%;
    top: 57%;
  }
  .roomdecor_desk{
    right: 28%;
    top: 52%;
  }
}

@media only screen and (max-width: 370px) {
  .logo{
    display: block;
  }
  .invLogo{
    margin-top: 20px;
    padding-left: 0px;
  }
}